"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CategorySVGDivider = void 0;
var react_1 = __importStar(require("react"));
var CategorySVGDivider = function (props) {
    (0, react_1.useEffect)(function () { }, []);
    return (react_1.default.createElement("div", { className: "flex justify-center items-center gap-4 w-full" },
        react_1.default.createElement("div", { className: "w-[75%] lg:w-[25%]" },
            react_1.default.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", preserveAspectRatio: "none", overflow: "visible", height: "15px", width: "full", viewBox: "0 0 24 24" },
                react_1.default.createElement("g", { id: "divider_1", d: "Divider 1" },
                    react_1.default.createElement("path", { d: "M24,8v12H0V8H24z M24,4v1H0V4H24z", fill: "#8c734b", opacity: 0.7, stroke: "none" })))),
        react_1.default.createElement("div", { className: "w-[50%] md:w-[30%] lg:w-[5%] lg:h-[5%]" },
            react_1.default.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", id: "Layer_2", "data-name": "Layer 2", viewBox: "0 0 174.73 98.05" },
                react_1.default.createElement("g", { id: "Layer_1", "data-name": "Layer 1" },
                    react_1.default.createElement("path", { d: "m98.04,46.08c0-1.78-.1-3.57-.3-5.33l-.14-1.2h-33.49l-5.88,5.88v12.95h20.36c-1.02,5.15-3.24,9.84-6.34,13.82-5.66,4.4-12.73,7.01-20.28,7.01-18.27,0-33.13-14.87-33.13-33.13,0-7.63,2.6-14.67,6.95-20.29,5.61-4.36,12.66-6.97,20.3-6.97,11.85,0,22.88,6.42,28.78,16.76l.39.68h14.21l5.88-5.88-.77-1.87C87.4,11.2,70.68,0,51.96,0c-12.71,0-24.24,5.17-32.58,13.53l-5.8,5.8h0C5.19,27.68,0,39.22,0,51.96c0,25.41,20.67,46.08,46.08,46.08,12.69,0,24.19-5.15,32.53-13.47h.01s.04-.05.06-.07l5.78-5.78h0c8.38-8.35,13.58-19.9,13.58-32.63m-46.08,43.37c-23.92,0-43.37-19.46-43.37-43.37S28.05,2.72,51.96,2.72c16.93,0,32.12,9.73,39.27,24.97h-8.53c-6.48-10.78-18.18-17.45-30.74-17.45-19.77,0-35.85,16.08-35.85,35.85s16.08,35.85,35.85,35.85c17.63,0,32.88-13.15,35.45-30.58l.23-1.55h-20.82v-7.53h28.34c.11,1.27.17,2.54.17,3.81,0,23.91-19.46,43.37-43.37,43.37", fill: "#8c734b" }),
                    react_1.default.createElement("path", { d: "m90.27,78.72h0l5.78,5.78s.04.04.06.06h.01c8.34,8.33,19.84,13.48,32.53,13.48,25.41,0,46.08-20.68,46.08-46.08,0-12.74-5.19-24.28-13.58-32.63h0s-5.8-5.8-5.8-5.8C147.01,5.18,135.48,0,122.77,0,104.05,0,87.33,11.2,80.16,28.53l-.77,1.87,5.88,5.88h14.21l.39-.68c5.91-10.34,16.93-16.76,28.78-16.76,7.64,0,14.69,2.6,20.3,6.96,4.35,5.61,6.95,12.65,6.95,20.29,0,18.27-14.87,33.13-33.13,33.13-7.55,0-14.62-2.61-20.28-7.01-3.1-3.97-5.33-8.67-6.34-13.82h20.36v-12.95l-5.88-5.88h-33.49l-.14,1.2c-.2,1.75-.3,3.55-.3,5.33,0,12.74,5.2,24.29,13.58,32.63m-10.87-32.63c0-1.27.05-2.55.17-3.81h28.34v7.53h-20.82l.23,1.55c2.58,17.43,17.82,30.58,35.45,30.58,19.77,0,35.85-16.08,35.85-35.85s-16.08-35.85-35.85-35.85c-12.56,0-24.26,6.67-30.74,17.45h-8.53c7.14-15.24,22.34-24.97,39.27-24.97,23.91,0,43.37,19.45,43.37,43.37s-19.45,43.37-43.37,43.37-43.37-19.46-43.37-43.37", fill: "#8c734b" })))),
        react_1.default.createElement("div", { className: "w-[75%] lg:w-[25%]" },
            react_1.default.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", preserveAspectRatio: "none", overflow: "visible", height: "15px", width: "full", viewBox: "0 0 24 24" },
                react_1.default.createElement("g", { id: "divider_2", d: "Divider 2" },
                    react_1.default.createElement("path", { d: "M24,8v12H0V8H24z M24,4v1H0V4H24z", fill: "#8c734b", opacity: 0.7, stroke: "none" }))))));
};
exports.CategorySVGDivider = CategorySVGDivider;
