"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopPage = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var categories_1 = require("../../../api/calls/product/categories");
var products_1 = require("../../../api/calls/product/products");
var recently_viewed_1 = require("../../../api/calls/product/recently_viewed");
var getRootPath_1 = require("../../../helpers/getRootPath");
var categorySVGDivider_1 = require("../../../components/categorySVGDivider");
var gemelliRings_1 = require("../../../components/gemelliRings");
var categoryProductsTile_1 = require("../../../components/categoryProductsTile");
var ShopPage = function () {
    var location = (0, react_router_dom_1.useLocation)();
    var rootPath = (0, getRootPath_1.getRootPath)(location);
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _a = (0, react_1.useState)([]), categories = _a[0], setCategories = _a[1];
    var _b = (0, react_1.useState)([]), recentlyViewed = _b[0], setRecentlyViewed = _b[1];
    var _c = (0, react_1.useState)([]), refs = _c[0], setRefs = _c[1];
    var localRefs = [];
    var _d = (0, react_1.useState)([]), categoryProducts = _d[0], setCategoryProducts = _d[1];
    var _e = (0, react_1.useState)(false), isLoading = _e[0], setIsLoading = _e[1];
    var _f = (0, react_1.useState)(false), fixBarToTopBar = _f[0], setFixBarToTopBar = _f[1];
    (0, react_1.useEffect)(function () {
        (0, categories_1.getCategories)({
            page: "1",
        }).then(function (response) {
            setCategories(response.content.product_category_list);
        });
    }, []);
    (0, react_1.useEffect)(function () {
        var _a;
        if (user.user._id) {
            (0, recently_viewed_1.getRecentlyViewedProducts)({
                user_id: (_a = user.user._id) !== null && _a !== void 0 ? _a : "",
            }).then(function (response) {
                var _a;
                setRecentlyViewed((_a = response.content) !== null && _a !== void 0 ? _a : []);
            });
        }
    }, [user]);
    (0, react_1.useEffect)(function () {
        getProductsForEachCategory();
    }, [categories]);
    var getProductsForEachCategory = function () { return __awaiter(void 0, void 0, void 0, function () {
        var categoryProductsLocal_1, lastIndex_1, _loop_1, _i, categories_2, category;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(categories && categories.length > 0)) return [3 /*break*/, 4];
                    categoryProductsLocal_1 = [];
                    setIsLoading(true);
                    lastIndex_1 = categories.length - 1;
                    _loop_1 = function (category) {
                        var currentIndex;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    currentIndex = categories.indexOf(category);
                                    return [4 /*yield*/, (0, products_1.getProducts)({
                                            category_id: category._id,
                                            page: "1",
                                            // size: "10",
                                        }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                                            var list, count;
                                            return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0:
                                                        list = response.content.product_list;
                                                        count = response.content.count;
                                                        return [4 /*yield*/, categoryProductsLocal_1.push({
                                                                name: category.name,
                                                                products: list,
                                                                count: count,
                                                            })];
                                                    case 1:
                                                        _a.sent();
                                                        if (currentIndex === lastIndex_1) {
                                                            setCategoryProducts(categoryProductsLocal_1);
                                                            setIsLoading(false);
                                                        }
                                                        return [2 /*return*/];
                                                }
                                            });
                                        }); })];
                                case 1:
                                    _b.sent();
                                    return [2 /*return*/];
                            }
                        });
                    };
                    _i = 0, categories_2 = categories;
                    _a.label = 1;
                case 1:
                    if (!(_i < categories_2.length)) return [3 /*break*/, 4];
                    category = categories_2[_i];
                    return [5 /*yield**/, _loop_1(category)];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3:
                    _i++;
                    return [3 /*break*/, 1];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    (0, react_1.useEffect)(function () {
        window.addEventListener("scroll", function (e) { return handleNavigation(e); });
        return function () {
            // return a cleanup function to unregister our function since it will run multiple times
            window.removeEventListener("scroll", function (e) { return handleNavigation(e); });
        };
    }, []);
    var handleNavigation = function (e) {
        var scrollPosition = window.scrollY;
        if (scrollPosition > 180) {
            setFixBarToTopBar(true);
            // console.log("scrollPosition", scrollPosition);
        }
        else if (scrollPosition < 180) {
            setFixBarToTopBar(false);
        }
    };
    var executeScroll = function (refs, index) {
        if (refs[index] && refs[index].current) {
            refs[index].current.scrollIntoView({ behavior: "smooth", block: "center", top: 0 });
        }
    };
    return (react_1.default.createElement("div", { className: "flex flex-col items-center" },
        react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, { className: "flex flex-col gap-8" },
            react_1.default.createElement("div", { className: "flex flex-col gap-8" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "m e n u", bold: true, textColor: "text-white", size: "2xlarge", center: true, uppercase: true }),
                react_1.default.createElement(gemelliRings_1.GemelliRings, null),
                react_1.default.createElement("div", { className: !fixBarToTopBar
                        ? "flex flex-col lg:flex-row justify-center items-center lg:gap-0 gap-4" //
                        : "flex flex-col lg:flex-row justify-center items-center lg:gap-0 gap-4 fixed top-0 left-0 right-0 bg-primary p-4 z-50" }, !isLoading &&
                    categories.map(function (category, index) {
                        return (react_1.default.createElement("div", { onClick: function () { return executeScroll(refs, index); }, key: "".concat(index, "-").concat(category.name), className: "flex hover:cursor-pointer hover:underline underline-offset-4 hover:text-shadow-light" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: category.name, bold: true, textColor: "text-white", size: fixBarToTopBar ? "large" : "medium" }),
                            categories.length - 1 !== index && (react_1.default.createElement("div", { className: "hidden lg:flex" },
                                react_1.default.createElement(doshx_controls_web_1.DividerControl, { lineColor: "bg-shadow-light", orientation: "vertical", addPadding: true })))));
                    }))),
            react_1.default.createElement(categorySVGDivider_1.CategorySVGDivider, null),
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "PLEASE NOTE : OUR DRESS CODE IS STRICTLY SMART CASUAL.", center: true, bold: true, textColor: "text-white", size: "large", uppercase: true }),
            !isLoading &&
                categoryProducts &&
                categoryProducts.length > 0 &&
                categoryProducts.map(function (categoryProduct, index) {
                    var lastIndex = categoryProducts.length - 1;
                    var isLast = index === lastIndex;
                    return (react_1.default.createElement(categoryProductsTile_1.CategoryProductsTile, { key: "".concat(index, "-").concat(categoryProduct.name), category: categoryProduct.name, products: categoryProduct.products, isLast: isLast, refs: function (ref) {
                            localRefs.push(ref);
                            if (isLast)
                                setRefs(localRefs);
                        } }));
                }),
            isLoading && react_1.default.createElement(doshx_controls_web_1.CircularLoaderControl, { size: "xlarge", progressColor: "text-shadow-light" }),
            react_1.default.createElement("div", null,
                react_1.default.createElement(gemelliRings_1.GemelliRings, { display_multiple: true }),
                react_1.default.createElement(gemelliRings_1.GemelliRings, { display_multiple: true })))));
};
exports.ShopPage = ShopPage;
