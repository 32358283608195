const colors = {
  //TODO: add more colors as needed
  // primary: "#1D4D99",
  primary: "#172945",
  disabled: "#B0B0B0",
  success: "#00AA00",
  error: "#AA0000",
  borders: "#888888",
  // new colors
  dark: "#000000",
  light: "#ffffff",
  // brown shades
  shadow : {
    light: "#c3af95",
    dark: "#422f2d",
    // dark: "8c734b",
    blue: "#1d2431",
  },
};

module.exports = colors;