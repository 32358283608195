"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VariantProductListingTile = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var VariantProductListingTile = function (props) {
    var name = props.name, price = props.price, isSelected = props.isSelected, variantRef = props.variantRef, onClick = props.onClick, onCheck = props.onCheck;
    (0, react_1.useEffect)(function () { }, []);
    return (react_1.default.createElement("div", { className: "\n    hover:cursor-pointer \n    col-span-3 mr-4 mb-4", onClick: function () {
            if (onClick)
                onClick();
        } },
        react_1.default.createElement("div", { className: "flex items-center justify-between" },
            react_1.default.createElement("div", { className: "flex items-center" },
                react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { size: "small" //
                    , checkedColor: "text-shadow-light", uncheckedColor: "text-white", label: name, textColor: "text-shadow-light", decorationColor: "decoration-transparent", value: isSelected, onChange: function (v) {
                        if (onCheck)
                            onCheck(v, variantRef);
                    } })),
            react_1.default.createElement("div", { className: "flex-grow border-b border-dashed mx-1" }),
            react_1.default.createElement("div", null,
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: doshx_controls_web_1.Utilities.formatMoney({
                        value: price,
                        symbol: "R",
                        decimalPlaces: 2,
                        symbolSeparation: true,
                    }), textColor: "text-white", size: "medium", center: true })))));
};
exports.VariantProductListingTile = VariantProductListingTile;
