"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductTile = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var productListingTile_1 = require("./productListingTile");
var react_router_dom_1 = require("react-router-dom");
var react_redux_1 = require("react-redux");
var add_product_thunk_1 = require("../reducers/slices/basket/thunks/add_product_thunk");
var pro_duotone_svg_icons_1 = require("@fortawesome/pro-duotone-svg-icons");
var convertToHtml_1 = require("./convertToHtml");
var variantProductListingTile_1 = require("./variantProductListingTile");
var constants_1 = require("../screens/pages/shop/constants");
var ProductTile = function (props) {
    var product = props.product, index = props.index;
    var navigate = (0, react_router_dom_1.useNavigate)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_1.useState)(1), quantity = _a[0], setQuantity = _a[1];
    var _b = (0, react_1.useState)(false), isHtmlString = _b[0], setIsHtmlString = _b[1];
    var _c = (0, react_1.useState)(""), productDescriptionInHtml = _c[0], setProductDescriptionInHtml = _c[1];
    var isAddingToBasket = (0, react_redux_1.useSelector)(function (state) { return state.basketState; }).isAddingToBasket;
    var _d = (0, react_1.useState)(null), selectedVariations = _d[0], setSelectedVariations = _d[1];
    var _e = (0, react_1.useState)(false), hasSelectedVariations = _e[0], setHasSelectedVariations = _e[1];
    var _f = (0, react_1.useState)(), variantSelectedRef = _f[0], setVariantSelectedRef = _f[1];
    var _g = (0, react_1.useState)("0"), totalProductPrice = _g[0], setTotalProductPrice = _g[1];
    var _h = (0, react_1.useState)(true), hasVariants = _h[0], setHasVariants = _h[1];
    (0, react_1.useEffect)(function () {
        var _a;
        if (product.description)
            setIsHtmlString(isHTML(product.description));
        var defaultVatiant = (_a = product === null || product === void 0 ? void 0 : product.variations[0]) === null || _a === void 0 ? void 0 : _a.variations.filter(function (v) { return v.status != constants_1.OUT_OF_STOCK; }).at(0);
        if (defaultVatiant) {
            setSelectedVariations(__assign(__assign({}, defaultVatiant), { type: product.variations[0].type }));
        }
        else {
            setSelectedVariations(null);
        }
        if (product.variations && product.variations.length > 0) {
            setHasVariants(true);
        }
        else {
            setHasVariants(false);
        }
    }, [product]);
    // set the price
    (0, react_1.useEffect)(function () {
        if (selectedVariations) {
            // set the total product price as the default variant price plus the product price
            setTotalProductPrice((parseFloat(selectedVariations.price) + parseFloat(product.price)).toFixed(2));
            setVariantSelectedRef(selectedVariations.ref);
            setHasSelectedVariations(true);
        }
        else {
            // set the total prodct price as the product price
            setTotalProductPrice(product.price);
            setVariantSelectedRef(undefined);
            setHasSelectedVariations(false);
        }
    }, [selectedVariations]);
    (0, react_1.useEffect)(function () {
        if (isHtmlString)
            setProductDescriptionInHtml(product.description);
    }, [isHtmlString]);
    function isHTML(str) {
        var doc = new DOMParser().parseFromString(str, "text/html");
        return Array.from(doc.body.childNodes).some(function (node) { return node.nodeType === 1; });
    }
    return (react_1.default.createElement("div", { className: "grid lg:grid-rows-2" },
        react_1.default.createElement("div", { key: "".concat(index, "-").concat(product._id), className: "\n      grid \n      lg:grid-cols-2 \n      lg:grid-flow-col \n      grid-cols-1 \n      lg:gap-4 \n      items-center" },
            react_1.default.createElement(productListingTile_1.ProductListingTile, { key: index, name: product.title, price: totalProductPrice }),
            react_1.default.createElement("div", { className: "lg:hidden col-span-3" }, product.status !== constants_1.OUT_OF_STOCK &&
                product.variations &&
                product.variations.map(function (variation, index) {
                    var _a;
                    if (variation.variations && variation.variations.length > 0) {
                        return (react_1.default.createElement("div", { key: "".concat(index, "-").concat(variation._id) },
                            react_1.default.createElement("div", { className: "flex pl-[1.75rem] items-center gap-4" },
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Please select a ".concat(variation.title), textColor: "text-shadow-light", size: "small", bold: true, uppercase: true }),
                                react_1.default.createElement("img", { src: variation.image_url, className: "w-14 h-14 object-contain" })),
                            ((_a = variation.variations) !== null && _a !== void 0 ? _a : [])
                                .filter(function (x) { return x.status != constants_1.OUT_OF_STOCK; })
                                .map(function (variant, variantKey) {
                                return (react_1.default.createElement(variantProductListingTile_1.VariantProductListingTile, { key: variantKey, name: variant.title, variantRef: variant.ref, price: variant.price, isSelected: variant.ref === variantSelectedRef ? true : false, onCheck: function (v, x) {
                                        setHasSelectedVariations(v);
                                        setVariantSelectedRef(x);
                                        if (v) {
                                            setSelectedVariations(__assign(__assign({}, variant), { type: variation.type }));
                                        }
                                        else {
                                            setSelectedVariations(null);
                                        }
                                    } }));
                            })));
                    }
                })),
            react_1.default.createElement("div", { className: "\n        grid \n        lg:grid-cols-1 \n        lg:gap-0 \n        gap-4 \n        grid-cols-3 \n        md:items-center \n        md:justify-start\n        md:grid-flow-col" },
                react_1.default.createElement("div", { className: "lg:hidden col-span-3" }, isHtmlString ? (react_1.default.createElement(convertToHtml_1.ConvertToHtmlComponent, { htmlString: productDescriptionInHtml, color: "white" })) : (react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: product.description, textColor: "text-white", size: "xsmall" }))),
                react_1.default.createElement("div", { className: "flex lg:col-span-2 col-span-1 md:justify-end items-center" },
                    react_1.default.createElement("div", { className: "col-span-1 border rounded-lg bg-shadow-light" },
                        react_1.default.createElement(doshx_controls_web_1.IncrementControl, { borderColor: "border-transparent", textColor: "text-primary", value: quantity, size: "xsmall", onChange: function (v) {
                                setQuantity(v);
                            } })),
                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { size: "xsmall", shape: "rounded", startIcon: pro_duotone_svg_icons_1.faCartShopping, iconColor: hasVariants ? (hasSelectedVariations ? "text-shadow-light" : "text-shadow-dark") : "text-shadow-light", loading: isAddingToBasket, enabled: hasVariants ? hasSelectedVariations && !isAddingToBasket : !isAddingToBasket, loadingColor: "text-shadow-light", backgroundDisabledColor: "bg-primary", backgroundColor: "bg-transparent", borderColor: "border-transparent", onClick: function () {
                            dispatch((0, add_product_thunk_1.add_product_thunk)({
                                product: product,
                                quantity: quantity,
                                selectedVariations: selectedVariations ? [selectedVariations] : null,
                            }));
                        } })))),
        react_1.default.createElement("div", { className: "lg:block hidden" },
            product.status !== constants_1.OUT_OF_STOCK &&
                product.variations &&
                product.variations.map(function (variation, index) {
                    var _a;
                    if (variation.variations && variation.variations.length > 0) {
                        return (react_1.default.createElement("div", { key: "".concat(index, "-").concat(variation._id), className: "-m-2" },
                            react_1.default.createElement("div", { className: "flex pl-[1.75rem] items-center gap-4" },
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Please select a ".concat(variation.title), textColor: "text-shadow-light", size: "small", bold: true, uppercase: true }),
                                react_1.default.createElement("img", { src: variation.image_url, className: "w-16 h-16 object-contain" })),
                            ((_a = variation.variations) !== null && _a !== void 0 ? _a : [])
                                .filter(function (x) { return x.status != constants_1.OUT_OF_STOCK; })
                                .map(function (variant, variantKey) {
                                return (react_1.default.createElement(variantProductListingTile_1.VariantProductListingTile, { key: variantKey, name: variant.title, variantRef: variant.ref, price: variant.price, isSelected: variant.ref === variantSelectedRef ? true : false, onCheck: function (v, x) {
                                        setHasSelectedVariations(v);
                                        setVariantSelectedRef(x);
                                        if (v) {
                                            setSelectedVariations(__assign(__assign({}, variant), { type: variation.type }));
                                        }
                                        else {
                                            setSelectedVariations(null);
                                        }
                                    } }));
                            })));
                    }
                }),
            isHtmlString ? (react_1.default.createElement(convertToHtml_1.ConvertToHtmlComponent, { htmlString: productDescriptionInHtml, color: "white" })) : (react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: product.description, textColor: "text-white", size: "xsmall" })))));
};
exports.ProductTile = ProductTile;
