"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfileDetailsWidget = void 0;
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var send_otp_1 = require("../../../api/calls/user/send_otp");
var update_user_details_1 = require("../../../api/calls/user/update_user_details");
var main_1 = require("../../../reducers/slices/main");
var user_1 = require("../../../reducers/slices/user");
var ProfileDetailsWidget = function (props) {
    var _a, _b, _c, _d, _e;
    var _f = props;
    var dispatch = (0, react_redux_1.useDispatch)();
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _g = (0, react_1.useState)(false), isEditing = _g[0], setIsEditing = _g[1];
    var _h = (0, react_1.useState)(false), isSaving = _h[0], setIsSaving = _h[1];
    var _j = (0, react_1.useState)((_a = user.user.name) !== null && _a !== void 0 ? _a : ""), name = _j[0], setName = _j[1];
    var _k = (0, react_1.useState)((_b = user.user.surname) !== null && _b !== void 0 ? _b : ""), surname = _k[0], setSurname = _k[1];
    var _l = (0, react_1.useState)((_c = user.user.email) !== null && _c !== void 0 ? _c : ""), email = _l[0], setEmail = _l[1];
    var _m = (0, react_1.useState)((_d = user.user.cell_number) !== null && _d !== void 0 ? _d : ""), phoneNumber = _m[0], setPhoneNumber = _m[1];
    var _o = (0, react_1.useState)(""), nameMessage = _o[0], setNameMessage = _o[1];
    var _p = (0, react_1.useState)(""), surnameMessage = _p[0], setSurnameMessage = _p[1];
    var _q = (0, react_1.useState)(""), emailMessage = _q[0], setEmailMessage = _q[1];
    var _r = (0, react_1.useState)(""), phoneNumberMessage = _r[0], setPhoneNumberMessage = _r[1];
    var _s = (0, react_1.useState)(""), otpMessage = _s[0], setOTPMessage = _s[1];
    var _t = (0, react_1.useState)(""), generatedOTP = _t[0], setGeneratedOTP = _t[1];
    var _u = (0, react_1.useState)(""), enteredOTP = _u[0], setEnteredOTP = _u[1];
    var _v = (0, react_1.useState)(0), otpCountdown = _v[0], setOTPCountdown = _v[1];
    var _w = (0, react_1.useState)(null), timer = _w[0], setTimer = _w[1];
    var maxCOTPCountdown = parseInt((_e = process.env.OTP_COUNTDOWN) !== null && _e !== void 0 ? _e : "30");
    var phoneNumberHasChnaged = user.user.cell_number != phoneNumber;
    (0, react_1.useEffect)(function () {
        return function () {
            if (timer) {
                // console.log("clearing timer");
                clearTimeout(timer);
            }
        };
    }, []);
    var validateDetails = function () {
        var messageCount = 0;
        setNameMessage("");
        setSurnameMessage("");
        setEmailMessage("");
        setPhoneNumberMessage("");
        setOTPMessage("");
        if (!user.user.name) {
            messageCount++;
            setEmailMessage("Name is required.");
        }
        if (!user.user.surname) {
            messageCount++;
            setSurnameMessage("Surname is required.");
        }
        if (!user.user.email) {
            messageCount++;
            setEmailMessage("Email is required.");
        }
        if (!user.user.cell_number) {
            messageCount++;
            setPhoneNumberMessage("Phone number is required.");
        }
        if (generatedOTP != enteredOTP) {
            messageCount++;
            setOTPMessage("OTP is incorrect.");
        }
        return messageCount ? false : true;
    };
    var onControlChanged = function (name, value) {
        var _a;
        dispatch((0, user_1.setUser)(__assign(__assign({}, user), { user: __assign(__assign({}, user.user), (_a = {}, _a[name] = value, _a)) })));
    };
    return (react_1.default.createElement(doshx_controls_web_1.BoxControl, { className: "flex flex-col gap-4 mt-8" },
        react_1.default.createElement("div", { className: "flex flex-row" },
            react_1.default.createElement("div", { className: "flex-1" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Details", size: "large", textColor: "text-secondary" })),
            react_1.default.createElement("div", { className: "flex flex-row gap-4" },
                react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: isEditing ? pro_solid_svg_icons_1.faSave : pro_solid_svg_icons_1.faPen, backgroundColor: isEditing ? "bg-success" : "bg-primary", iconColor: "text-white", size: "xsmall", loading: isSaving, onClick: function () {
                        var _a;
                        if (isEditing) {
                            if (validateDetails()) {
                                setIsSaving(true);
                                (0, update_user_details_1.update_user_details)({
                                    id: (_a = user.user._id) !== null && _a !== void 0 ? _a : "",
                                    name: name,
                                    surname: surname,
                                    email: email,
                                    cell_number: phoneNumber,
                                }).then(function (response) {
                                    setIsSaving(false);
                                    if (response.success) {
                                        dispatch((0, user_1.update_details_thunk)({
                                            name: name,
                                            surname: surname,
                                            email: email,
                                            phoneNumber: phoneNumber,
                                        }));
                                        setIsEditing(false);
                                    }
                                    else {
                                        dispatch((0, main_1.setMainModal)({ title: "Something went wrong!", messages: response.message }));
                                    }
                                });
                            }
                        }
                        else {
                            setIsEditing(true);
                        }
                    } }),
                isEditing && (react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: pro_solid_svg_icons_1.faClose, backgroundColor: "bg-primary", iconColor: "text-white", size: "xsmall", onClick: function () {
                        setIsEditing(false);
                    } })))),
        react_1.default.createElement("div", { className: "flex flex-col gap-4" },
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Name", value: name, disabled: !isEditing, size: "small", errorText: nameMessage, textColor: isEditing ? "text-secondary" : "text-disabled", labelColor: isEditing ? "text-secondary" : "text-disabled", borderColor: isEditing ? "border-borders-gray" : "border-disabled", onChange: function (v) { return setName(v); } }),
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Surname", value: surname, disabled: !isEditing, size: "small", errorText: surnameMessage, textColor: isEditing ? "text-secondary" : "text-disabled", labelColor: isEditing ? "text-secondary" : "text-disabled", borderColor: isEditing ? "border-borders-gray" : "border-disabled", onChange: function (v) { return setSurname(v); } }),
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Email", value: email, disabled: !isEditing, size: "small", errorText: emailMessage, textColor: isEditing ? "text-secondary" : "text-disabled", labelColor: isEditing ? "text-secondary" : "text-disabled", borderColor: isEditing ? "border-borders-gray" : "border-disabled", onChange: function (v) { return setEmail(v); } }),
            react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                react_1.default.createElement("div", { className: "flex flex-row gap-4" },
                    react_1.default.createElement("div", { className: "flex-1 flex flex-col gap-4" },
                        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Phone Number", type: "number", value: phoneNumber, disabled: !isEditing, size: "small", errorText: phoneNumberMessage, textColor: isEditing ? "text-secondary" : "text-disabled", labelColor: isEditing ? "text-secondary" : "text-disabled", borderColor: isEditing ? "border-borders-gray" : "border-disabled", onChange: function (v) { return setPhoneNumber(v); } }),
                        generatedOTP && phoneNumberHasChnaged && (react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "OTP", type: "number", value: enteredOTP, disabled: !isEditing, size: "small", errorText: otpMessage, textColor: isEditing ? "text-secondary" : "text-disabled", labelColor: isEditing ? "text-secondary" : "text-disabled", borderColor: isEditing ? "border-borders-gray" : "border-disabled", onChange: function (v) { return setEnteredOTP(v); } }))),
                    phoneNumberHasChnaged && (react_1.default.createElement("div", null, otpCountdown > 0 ? (react_1.default.createElement("div", { className: "w-10" },
                        react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: otpCountdown.toString(), size: "small", backgroundColor: "bg-success" }))) : (react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: pro_solid_svg_icons_1.faSend, backgroundColor: "bg-success", iconColor: "text-white", size: "small", onClick: function () {
                            var otp = doshx_controls_web_1.Utilities.generateOTP();
                            (0, send_otp_1.send_otp)({
                                otp: otp,
                                cell_number: phoneNumber,
                                name: name,
                            }).then(function (response) {
                                if (response.success) {
                                    setGeneratedOTP(otp);
                                    setOTPCountdown(maxCOTPCountdown);
                                    var timeLeft_1 = maxCOTPCountdown;
                                    if (timer) {
                                        // console.log("clearing timer");
                                        clearTimeout(timer);
                                    }
                                    setTimer(setInterval(function () {
                                        timeLeft_1 = timeLeft_1 - 1;
                                        setOTPCountdown(timeLeft_1);
                                        if (timer && timeLeft_1 === 0) {
                                            // console.log("clearing timer");
                                            clearTimeout(timer);
                                        }
                                    }, 1000));
                                }
                                else {
                                    dispatch((0, main_1.setMainModal)({ title: "Something went wrong!", messages: response.message }));
                                }
                            });
                        } })))))))));
};
exports.ProfileDetailsWidget = ProfileDetailsWidget;
