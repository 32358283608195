"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PopulatedBasket = void 0;
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var cancel_basket_1 = require("../../../api/calls/basket/cancel_basket");
var remove_products_1 = require("../../../api/calls/basket/remove_products");
var update_quantity_1 = require("../../../api/calls/basket/update_quantity");
var basket_1 = require("../../../reducers/slices/basket/basket");
var get_basket_thunk_1 = require("../../../reducers/slices/basket/thunks/get_basket_thunk");
var main_1 = require("../../../reducers/slices/main");
var PopulatedBasket = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var formatMoney = doshx_controls_web_1.Utilities.formatMoney;
    var basket = (0, react_redux_1.useSelector)(function (state) { return state.basketState; }).basket;
    var _a = (0, react_1.useState)(0), test = _a[0], setTest = _a[1];
    var _b = (0, react_1.useState)([]), selectedProducts = _b[0], setSelectedProducts = _b[1];
    return (react_1.default.createElement("div", { className: "flex flex-col lg:flex-row gap-x-4 gap-y-8" },
        react_1.default.createElement("div", { className: "flex-grow flex flex-col gap-4" },
            react_1.default.createElement(doshx_controls_web_1.BoxControl, { className: "flex flex-row justify-between items-center", borderColor: "border-secondary" },
                react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Select all", uncheckedColor: "text-white", checkedColor: "text-shadow-light", textColor: "text-shadow-light", value: selectedProducts.length === (basket === null || basket === void 0 ? void 0 : basket.product_list.length), onChange: function (v) {
                        if (v) {
                            for (var _i = 0, _a = basket.product_list; _i < _a.length; _i++) {
                                var p = _a[_i];
                                if (!selectedProducts.includes(p._id)) {
                                    selectedProducts.push(p._id);
                                }
                            }
                            setSelectedProducts(__spreadArray([], selectedProducts, true));
                        }
                        else {
                            setSelectedProducts([]);
                        }
                    } }),
                react_1.default.createElement("div", { className: "flex flex-row flex-wrap gap-8" },
                    react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: "CANCEL BASKET", textColor: "text-white", onClick: function () {
                            (0, cancel_basket_1.cancel_basket)({
                                _id: basket._id,
                            }).then(function (response) {
                                if (response.success) {
                                    dispatch((0, basket_1.setBasket)(null));
                                    navigate("/shop");
                                }
                                else {
                                    dispatch((0, main_1.setMainModal)({
                                        title: "Something went wrong!",
                                        messages: [response.message],
                                    }));
                                }
                            });
                        } }),
                    react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: "REMOVE SELECTED", textColor: "text-white", onClick: function () {
                            if (selectedProducts && selectedProducts.length) {
                                (0, remove_products_1.remove_products)({
                                    _id: basket._id,
                                    product_id_list: selectedProducts,
                                }).then(function (response) {
                                    if (response.success) {
                                        dispatch((0, get_basket_thunk_1.get_basket_thunk)());
                                    }
                                    else {
                                        dispatch((0, main_1.setMainModal)({
                                            title: "Something went wrong!",
                                            messages: [response.message],
                                        }));
                                    }
                                });
                            }
                            else {
                                dispatch((0, main_1.setMainModal)({
                                    title: "Selection required.",
                                    messages: ["Please make a selection!"],
                                }));
                            }
                        } }))),
            react_1.default.createElement("div", { className: "flex flex-col pl-4" }, basket &&
                basket.product_list.map(function (product, index) {
                    var _a, _b, _c, _d, _e;
                    // // console.log();
                    return (react_1.default.createElement("div", { key: "container-".concat(product._id) },
                        react_1.default.createElement("div", { className: "flex flex-none flex-row" },
                            react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { checkedColor: "text-shadow-light", uncheckedColor: "text-white", value: selectedProducts.includes(product._id), onChange: function () {
                                    var idx = selectedProducts.indexOf(product._id);
                                    if (selectedProducts.includes(product._id)) {
                                        selectedProducts.splice(idx, 1);
                                        setSelectedProducts(__spreadArray([], selectedProducts, true));
                                    }
                                    else {
                                        selectedProducts.push(product._id);
                                        setSelectedProducts(__spreadArray([], selectedProducts, true));
                                    }
                                    dispatch((0, basket_1.setBasket)(__assign({}, basket)));
                                } }),
                            react_1.default.createElement("div", { className: "flex-grow flex flex-row" },
                                product.image_url && (react_1.default.createElement("div", { className: "w-[80px] h-[80px]" },
                                    react_1.default.createElement("img", { className: "w-full h-full object-cover rounded-lg", src: (_a = product.image_url) !== null && _a !== void 0 ? _a : "" }))),
                                react_1.default.createElement("div", { className: "flex-1 flex flex-col gap-2" },
                                    react_1.default.createElement("div", { className: "flex-1 flex flex-col self-start pl-4" },
                                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: product.title, size: "small", textColor: "text-shadow-light" }),
                                        !product.on_promotion ? (react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: doshx_controls_web_1.Utilities.formatMoney({
                                                value: (_c = (_b = product.price) === null || _b === void 0 ? void 0 : _b.toString()) !== null && _c !== void 0 ? _c : "0",
                                            }), size: "small", textColor: "text-white" })) : (react_1.default.createElement("div", { className: "flex items-center justify-between" },
                                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: formatMoney({
                                                    value: (_d = product === null || product === void 0 ? void 0 : product.promotion_price) !== null && _d !== void 0 ? _d : "0",
                                                    decimalPlaces: 2,
                                                }), size: "small", textColor: "text-white" }),
                                            react_1.default.createElement("div", { className: "line-through decoration-white" },
                                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: formatMoney({
                                                        value: (_e = product === null || product === void 0 ? void 0 : product.price) !== null && _e !== void 0 ? _e : "0",
                                                        decimalPlaces: 2,
                                                    }), textColor: "text-shadow-light", size: "xsmall" }))))),
                                    react_1.default.createElement("div", { className: "flex items-center self-start pl-4 gap-4" }, product.variations &&
                                        product.variations.map(function (variant, index) {
                                            // each variant, in a product that exists in the basket, will only have 1 variation
                                            return (react_1.default.createElement(doshx_controls_web_1.BoxControl, { key: index, borderColor: "border-transparent", addPadding: false, backgroundColor: "bg-shadow-light" },
                                                react_1.default.createElement("div", { className: variant.image_url == null ? "flex py-1 px-2 items-center gap-4" : "flex px-2 items-center gap-4" },
                                                    variant.image_url && react_1.default.createElement("img", { className: " w-14 h-14 object-contain ", src: variant.image_url }),
                                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(variant.variations[0].title), size: "xsmall", textColor: "text-shadow-blue", bold: true, uppercase: true }))));
                                        })),
                                    react_1.default.createElement("div", { className: "flex items-center self-start pl-4 gap-4" }, product.deal && (react_1.default.createElement(doshx_controls_web_1.BoxControl, { key: 1, borderColor: "border-transparent", addPadding: false, backgroundColor: "bg-secondary" },
                                        react_1.default.createElement("div", { className: "p-2" },
                                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(product.deal.deal_name, " of R").concat(product.deal.discount, " applied to this product."), size: "small", textColor: "text-white" }))))),
                                    react_1.default.createElement("div", { className: "flex-grow justify-end self-end flex flex-row items-center gap-4" },
                                        react_1.default.createElement("div", { className: "bg-shadow-light rounded-lg" },
                                            react_1.default.createElement(doshx_controls_web_1.IncrementControl, { borderColor: "border-transparent", textColor: "text-primary", key: "increment-".concat(product._id), size: "small", value: parseInt(product.quantity), max: 10, onChange: function (v) {
                                                    // // console.log(v);
                                                    (0, update_quantity_1.update_quantity)({
                                                        _id: basket._id,
                                                        product: {
                                                            _id: product._id,
                                                            quantity: v.toString(),
                                                        },
                                                    }).then(function (response) {
                                                        if (response.success) {
                                                            // getTotal(basket.product_list);
                                                            // // console.log("here");
                                                            dispatch((0, get_basket_thunk_1.get_basket_thunk)());
                                                        }
                                                        else {
                                                            dispatch((0, main_1.setMainModal)({ title: "Something went wrong!", messages: [response.message] }));
                                                        }
                                                    });
                                                } })),
                                        react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: pro_solid_svg_icons_1.faTrash, backgroundColor: "bg-transparent" //"bg-gray-200"
                                            , iconColor: "text-white" //"text-gray-500"
                                            , size: "small", onClick: function () {
                                                (0, remove_products_1.remove_products)({
                                                    _id: basket._id,
                                                    product_id_list: [product._id],
                                                }).then(function (response) {
                                                    if (response.success) {
                                                        dispatch((0, get_basket_thunk_1.get_basket_thunk)());
                                                    }
                                                    else {
                                                        dispatch((0, main_1.setMainModal)({ title: "Something went wrong!", messages: [response.message] }));
                                                    }
                                                });
                                            } }))))),
                        index < basket.product_list.length - 1 && react_1.default.createElement(doshx_controls_web_1.DividerControl, null)));
                }))),
        react_1.default.createElement("div", { className: "lg:w-96" },
            react_1.default.createElement(doshx_controls_web_1.BoxControl, { className: "flex flex-col gap-4", backgroundColor: "bg-white", borderColor: "border-shadows-light", shadowColor: "shadow-shadows-dark/20" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Shopping Summary", size: "large", textColor: "text-primary" }),
                react_1.default.createElement("div", { className: "flex flex-row justify-between" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Discount Total", textColor: "text-primary" }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: doshx_controls_web_1.Utilities.formatMoney({
                            value: basket === null || basket === void 0 ? void 0 : basket.total_product_deals.toString(),
                            decimalPlaces: 2,
                        }), size: "large", textColor: "text-primary" })),
                react_1.default.createElement("div", { className: "flex flex-row justify-between" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Total", textColor: "text-primary" }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: doshx_controls_web_1.Utilities.formatMoney({
                            value: basket === null || basket === void 0 ? void 0 : basket.total.toString(),
                            decimalPlaces: 2,
                        }), size: "large", textColor: "text-primary" })),
                react_1.default.createElement("div", { className: "flex flex-row justify-between" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Outstanding Amount", textColor: "text-primary", bold: true }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: doshx_controls_web_1.Utilities.formatMoney({
                            value: basket === null || basket === void 0 ? void 0 : basket.outstanding_amount.toString(),
                            decimalPlaces: 2,
                        }), size: "large", bold: true, textColor: "text-primary" })),
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Checkout", textColor: "text-white", borderColor: "border-transparent", backgroundColor: "bg-primary", onClick: function () {
                        navigate("/checkout");
                    } }),
                react_1.default.createElement("div", { className: "flex flex-row justify-center" },
                    react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: "Back to Shop", textColor: "text-primary", onClick: function () {
                            navigate("/shop");
                        } }))))));
};
exports.PopulatedBasket = PopulatedBasket;
