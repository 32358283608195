"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CategoryProductsTile = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var categorySVGDivider_1 = require("./categorySVGDivider");
var productTile_1 = require("./productTile");
var CategoryProductsTile = function (props) {
    var category = props.category, products = props.products, isLast = props.isLast, refs = props.refs;
    var reference = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(function () {
        if (refs)
            refs(reference);
    }, [reference]);
    return (react_1.default.createElement(doshx_controls_web_1.BoxControl, { borderColor: "border-transparent" },
        react_1.default.createElement("div", { ref: reference, className: "flex" },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: category, textColor: "text-white", bold: true, size: "large" })),
        react_1.default.createElement("div", { key: category, className: "grid lg:grid-cols-2 grid-cols-1 lg:gap-8 gap-4" }, products &&
            products.length > 0 &&
            products.map(function (product, index) {
                return react_1.default.createElement(productTile_1.ProductTile, { key: "".concat(product, "+").concat(index), product: product, index: index });
            })),
        react_1.default.createElement(doshx_controls_web_1.DividerControl, { addPadding: true, lineColor: "bg-transparent" }),
        !isLast && react_1.default.createElement(categorySVGDivider_1.CategorySVGDivider, null)));
};
exports.CategoryProductsTile = CategoryProductsTile;
